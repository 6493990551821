<template>
  <div class="mine-online px-16 pt-16 pb-60">
    <Navbar title="在线签约"> </Navbar>
    <div v-if="contractList && contractList.length > 0">
      <div class="font-16 mb-24">
        点击“确认签署”即代表您已同意以下合同内容：
      </div>
      <CheckboxGroup v-model="checkList">
        <div
          @click="() => handleJump(item)"
          class="sign-card bda-4 mb-16 d-flex pa-16 bda justify-space-between"
          v-for="(item, index) in contractList"
          :key="index"
        >
          <div class="font-16 fw-500 d-flex">
            <div class="pt-4">
              <Checkbox class="mr-8 font-16" :name="index" shape="square" />
            </div>
            <div>
              <div>{{ item.contractName }}</div>
              <div class="font-12 text_secondary">
                {{ item.taxSourceCompanyName }}
              </div>
            </div>
          </div>
          <div class="lh-40">
            <Icon name="jt-h" />
          </div>
        </div>
      </CheckboxGroup>
      <div class="btn-box" @click="handleSigned">
        <Button size="large" type="primary">确认签署</Button>
      </div>
    </div>
    <Empty v-else imgWidth="0.84rem" :image="noContract" description="当前无合同"> </Empty>
  </div>
</template>
<script>
import { defineComponent, ref, onMounted } from '@vue/composition-api';
import { Checkbox, CheckboxGroup } from 'vant';
import { apiUnsignList, apiSignConstruct } from '@api/mine';
import { Button, Toast } from 'vant';
import { useRouterQuery } from '@hook/useRouterQuery';
import { useRouter } from '@/utils/compsitionHack';
import { PageEnum } from '@/enums/pageEnum';

export default defineComponent({
  name: 'Online',
  components: {
    Button,
    CheckboxGroup,
    Checkbox
  },
  props: {
    dataDetail: {
      type: Object,
      default: () => {}
    }
  },
  setup(_, ctx) {
    const contractList = ref([]);
    const checkList = ref([]);
    const router = useRouter();
    const query = useRouterQuery();
    const noContract = require('@/assets/htkb.png');
    const handleSigned = async () => {
      try {
        const res = apiSignConstruct({
          driverId: query.driverId
        });
        if (res) {
          Toast.success('签约成功！');
          if (query.routerBack === '1') {
            ctx.root.$routerBack.call(ctx.root._router);
          } else {
            router.push(PageEnum.CONTRACT_LIST);
          }
        }
      } catch (err) {
        Toast.fail(err);
      }
    };
    const handleJump = async item => {
      router.push({
        path: PageEnum.AGREEMENT,
        query: {
          taxSourceId: item.taxSourceId,
          driverId: query.driverId
        }
      });
    };
    onMounted(() => {
      (async () => {
        const [, unsignList] = await ctx.root.$await(
          apiUnsignList({
            driverId: query.driverId
          })
        );
        if (unsignList.data.length) {
          contractList.value = unsignList.data;
          checkList.value = contractList.value.map((item, index) => index);
        } else {
          Toast.success('当前无未签约合同!');
        }
      })();
    });
    return {
      checkList,
      contractList,
      noContract,
      handleSigned,
      handleJump
    };
  }
});
</script>
<style lang="less">
.mine-online {
  min-height: 100%;
  .van-checkbox {
    .van-checkbox__icon {
      font-size: 0.16rem;
    }
  }
}
</style>
